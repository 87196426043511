import React from "react";
import pacificopen from "../../images/pacific oahu open.jpg";
import { Button } from "react-bootstrap";

function PacificOpen() {
  return (
    <>
      <div className="row justify-content-center text-center mb-4">
        <img src={pacificopen} alt="pacific open" className="eventImg" />
      </div>
      <div className="row justify-content-center text-center mb-4">
        <div className="col">
          <Button
            href="https://smoothcomp.com/en/event/19865"
            variant="success"
            size="lg"
            target="_blank"
            rel="noopener noreferrer"
          >
            REGISTER HERE
          </Button>
        </div>
      </div>
      <div className="row justify-content-center text-center">
        <p>
          Click{" "}
          <a
            href="https://ibjjf.com/uniform/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{" "}
          for Gi and no-Gi uniform requirements
        </p>
      </div>
      {/* <div className="row justify-content-center text-center">
        <p className="fw-bold">
          Location:{" "}
          <a
            href="https://maps.app.goo.gl/7KcTo68n3DiYj4sHA"
            target="_blank"
            rel="noopener noreferrer"
          >
            Radford High School, 4361 Salt Lake Blvd., Honolulu, HI 96818
          </a>
        </p>
        <p>
          <b>Time:</b> Sunday, December 1, 2024, 8:00 AM - TBD
        </p>
        <p className="mb-0 fw-bold">Registration:</p>
        <ul style={{ listStyleType: "none" }}>
          <li>$90 Early Registration until May 30th, 2024</li>
          <li>First 100 competitors receive a free T-shirt</li>
          <li>$110 Regular Registration until June 15th, 2024</li>
          <li>$130 Late Registration until July 1st, 2024</li>
          <li>Black belts 50% off registration fee</li>
          <li>Digital Membership Card: $10 renewal (Free this year)</li>
        </ul>
        <p className="mb-0 fw-bold">Absolute Division (Adults and Masters):</p>
        <ul style={{ listStyleType: "none" }}>
          <li>Cash Payout</li>
          <li>Blue, Purple, Brown, and Black Belts</li>
          <li>$20 Registration fee</li>
          <li>Fee waived for Brown and Black Belts</li>
          <li>
            Competitor must compete in their respective division prior to
            competing in the absolute division
          </li>
          <li>Blue and Purple Belt bracket: 8 competitors minimum required</li>
          <li>Brown and Black Belts: 4 competitors minimum required</li>
        </ul>
        <p className="mb-0 fw-bold">Payment Methods:</p>
        <ul style={{ listStyleType: "none" }}>
          <li>Credit Card (through Smoothcomp)</li>
        </ul>
      </div> */}
    </>
  );
}

export default PacificOpen;
